<template>
    <div style="display: flex; flex-direction: column; align-items: center;margin-bottom: 1rem; padding: 1rem">
        <div class="card-container fadein">
            <div class="card-content">
                <img class="card-icon" :src="card" />
                <div class="card-banner">
                    <div style="font-size: 22px;width: 100%;display: flex; justify-content: space-evenly">
                        <button @click="create_deposit()" class="highlight-primary th">ฝาก</button>
                        <button @click="create_withdraw()" class="highlight-primary th">ถอน</button>
                    </div>
                </div>
            </div>
            <div class="card-info">
                <div style="margin: 1rem">
                    <h3>USERNAME:</h3>
                    <h1>{{username}}</h1>
                </div>
                <div style="margin: 1rem">
                    <h3>เครดิตพร้อมเล่น: ({{count_num}})</h3>
                    <h1>{{number_comma(credit)}} THB</h1>
                </div>
            </div>
        </div>

        <div class="profile-container">
            <div class="game-rules" style="display: flex; align-items: center;">
                <img style="height: 100px;margin: 1rem" src="https://member.databetx.com/_nuxt/img/f0aa184.png" />
                <div>
                    <h5 style="color: white">ชื่อบัญชี</h5>
                    <h3>{{staff_bank_name}}</h3>
                    <br>
                    <h5 style="color: white">เลขบัญชี <button @click="copy_bank(staff_bank)" style="line-height: 0; height: 1.5rem;font-size: 12px" class="highlight-primary th">คัดลอก</button></h5>
                    <h3>{{staff_bank}}</h3>
                </div>
            </div>
        </div>

        <div class="profile-container">
            <div class="game-rules">
                <p class="font-weight-bold">วิธีโอนเงิน หรือ เติมเงิน <a href="#" @click="show_katigar()">กฎและกติกา</a></p>
                <p>1. ยอดฝาก/ถอนขั้นต่ำ 100 บาท</p>
                <p>2. การโอนเงินเข้าเล่นเกม จะต้องโอนจากบัญชีที่สมัครเท่านั้น
                    <br>ถ้าโอนจากบัญชีที่ไม่ได้ลงทะเบียนไว้ ยอดเงินจะไม่เข้าอัตโนมัติ และทางเราไม่รับผิดชอบใดๆ</p>
                <p>3. ต้องการเติมเงินวิธีอื่นๆ กรุณาติดต่อ <a class="primary-title" @click="forgot_password()">Admin</a> </p>
                <p>4. หากเงินไม่เข้า ต้องแจ้งแอดมินภายใน 24 ชั่วโมง หากเกินเวลากว่านั้นงดทำรายการทุกกรณี</p>
            </div>
        </div>

        <div class="profile-container">
            <h5>ประวัติฝาก</h5>
            <table class="table-layout">
                <tr>
                    <td>เวลา</td>
                    <td>ยอด</td>
                    <td>สถานะ</td>
                </tr>
                <tr v-for="({ created_at, amount, status}, i) in deposit" :key="i.key" style="font-size: 12px">
                    <td>{{time_format(created_at)}}</td>
                    <td>{{number_comma(amount)}} THB</td>
                    <td><span :class="`badge text-bg-${trx_class[status]}`">{{trx_status[status]}}</span></td>
                </tr>
            </table>
        </div>
        
        <div class="profile-container">
            <h5>ประวัติถอน</h5>
            <table class="table-layout">
                <tr>
                    <td>เวลา</td>
                    <td>ยอด</td>
                    <td>สถานะ</td>
                </tr>
                <tr v-for="({ created_at, amount, status}, i) in withdraw" :key="i.key" style="font-size: 12px">
                    <td>{{time_format(created_at)}}</td>
                    <td>{{number_comma(amount)}} THB</td>
                    <td><span :class="`badge text-bg-${trx_class[status]}`">{{trx_status[status]}}</span></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import Tesseract from 'tesseract.js';
import UserService from '../../services/UserService';
import Swal from 'sweetalert2'
import moment from 'moment';

export default {
    name: 'profile',
    props: {
        deposit: {
            type: Array,
            default: () => {
                return []
            }
        },
        withdraw: {
            type: Array,
            default: () => {
                return []
            }
        },
        username: String,
        user_bank: String,
        credit: Number
    },
    data() {
        return {
            katigar_path: require('@/assets/Katigar.jpeg'),
            progress: 0,
            staff_bank_name: '',
            card: require('@/assets/ICON.png'),
            crown: require('@/assets/crown.svg'),
            staff_bank: '',
            trx_status: {
                0: 'กำลังดำเนินการ',
                1: 'อนุมัติ',
                2: 'ไม่อนุมัต'
            },
            trx_class: {
                0: 'warning',
                1: 'success',
                2: 'danger'
            },
            count_down: null,
            count_num: 30,
            line_poster: require('@/assets/line_poster.png'),
        }
    },
    methods: {
        forgot_password () {
            const line = 'https://line.me/ti/p/~@uday888'
            Swal.fire({
                html: `<a target="_blank" href="${line}" /><img style="width: 100%" src="${this.line_poster}" /></a>`,
                background: '#1c2530',
                showConfirmButton: false
            })
        },
        show_katigar() {
            Swal.fire({
                imageUrl: this.katigar_path,
                background: '#1c2530',
                confirmButtonText: 'ตกลง'
                // imageHeight: 1500,
                // imageAlt: 'A tall image'
            })
        },
        time_format(datetime) {
            return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY HH:mm')
        },
        copy_bank(value) {
            navigator.clipboard.writeText(value);
        },
        number_comma(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        async previewFiles(e) {
            for (var i = 0; i < e.target.files.length; i++) {
                const f = e.target.files[i]
                var file = f
                var img_url = URL.createObjectURL(file)

                const { data: { text } } = await Tesseract
                    .recognize(img_url, 'tha', {
                        logger: ({ progress }) => {
                            this.progress = (progress * 100).toFixed(2)
                        }
                    })
                const texts = text.replaceAll(' ', '').split('\n')
                const result = {
                    account: texts[texts.findIndex(e => e.includes('เลขที่รายการ')) - 1],
                    refno: texts[texts.findIndex(e => e.includes('เลขที่รายการ')) + 1].split('[')[0],
                    amount: texts[texts.findIndex(e => e.includes('จํานวน:')) + 1].split('THB')[0],
                }

                console.log(result);
            }
        },
        check_user() {
            if (!localStorage.getItem('user')) {
                this.$router.push('/')
            }
        },
        open_game() {
            window.location.href = `https://www.gwp6868.net/PlayGame?token=${localStorage.getItem('user')}&appid=TGZU&gameCode=dc7sh3dfmjpio&language=en&mobile=false&redirectUrl=http://uday888.com/`;
        },
        async get_staff() {
            const { bank_name, bank } = await UserService.get_staff()
            this.staff_bank_name = bank_name
            this.staff_bank = bank
        },
        async create_deposit() {
            await Swal.fire({
                title: `ระบบฝากเครดิตอัตโนมัติ`,
                text: `ยอดของคุณจะถูกอัพเดทภายใน 30 วินาที (บัญชีของคุณ ${this.user_bank})`,
                // input: 'number',
                // inputValidator: (result) => {
                //     return result < 100 && `จำนวนเงินไม่ถูกต้อง!`
                // },
                // showCancelButton: true,
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#1c2530',
                // showLoaderOnConfirm: true,
                preConfirm: async () => {
                    return true
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
        },
        async create_withdraw() {
            await Swal.fire({
                title: 'จำนวนที่ต้องการถอน',
                text: 'ถอนเงินทันทีภายใน 2 นาที หลังจากกดปุ่ม "ถอนเงิน"',
                input: 'number',
                inputValidator: (result) => {
                    return result < 100 && `จำนวนเงินไม่ถูกต้อง!`
                },
                showCancelButton: true,
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ถอนเงิน',
                confirmButtonColor: '#1c2530',
                showLoaderOnConfirm: true,
                preConfirm: async (login) => {
                    return await UserService.withdraw(Number(login))
                        .then(response => {
                            if (response.status == 'fail') {
                                alert('แจ้งถอนเงินล้มเหลว')
                            } else {
                                this.$emit('updateCredit', true)
                                alert('แจ้งถอนเงินสำเร็จ')
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            alert('แจ้งถอนเงินล้มเหลว')
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
        },
        count_down_int() {
            this.count_down = setInterval(() => {
                this.count_num = this.count_num -1
                if (!this.count_num) {
                    this.$emit('updateCredit', true)
                    this.count_num = 30
                }
            },1000)
        }
    },
    mounted() {
        // this.get_payment()
        this.count_down_int()
        this.check_user()
        this.get_staff()
    }
}
</script>

<style lang="less" scoped>
    h3, h5 {
        margin: 0
    }
    .card-banner {
        position: absolute;
        width: 100%;
        bottom: 10%;
        background: linear-gradient(135deg,#1c253093,#2934448f 45%,#fde38396;)padding-box;
        border: 2px solid #fde383;
        border-left: none;
        border-right: none;

        button {
            display: flex;
            align-items: center;
            padding: 0 2rem;
            color: white
        }
    }
    .card-container {
        margin: 1rem;
        width: 100%;
        padding: 1rem;
        position: relative;
        overflow: hidden;
        .card-content {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 33vw;
            min-height: 300px;
            object-fit: cover;
            border: 5px solid #fde383;
            border-radius: 30px;
            background-color: #1c2530;
        }
        .card-icon {
            width: 50%;
            position: absolute;
            right: -14%;
            top: 0%;
        }
        .card-info {
            position: absolute;
            left: 5%;
            top: 10%;
            h3 {
                color: white
            }
        }
    }
    .table-layout {
        width: 100%;
        text-align: center;
        table-layout: fixed;
    }
    .profile-container {
        width: 100%;
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        margin: 0.25rem;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #fde383;
        background-color: #1c2530;
    }
</style>